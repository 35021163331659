@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .link-normal {
        @apply inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800;
    }

    .btn {
        @apply rounded relative inline-block align-middle px-3.5 py-2 m-1 active:shadow-none shadow-lg overflow-hidden;
    }

    .btn-primary {
        @apply border-b-4 border-l-2 bg-gradient-to-tr active:border-purple-600 from-purple-600 to-purple-500 border-purple-700 text-white;
    }

    .btn-secondary {
        @apply border active:border-purple-600 border-purple-500 text-purple-500;
    }

    .btn-danger {
        @apply border-b-4 border-l-2 bg-gradient-to-tr active:border-red-600 from-red-600 to-red-500 border-red-700 text-white;
    }

    .btn-ifx {
        /* inner effect */
        @apply absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* CSS */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
